<template>
  <div
    class="alert-page wrapper ff-flama">
    <AlertModal
      :show="showIPUserModal"
      no-close-button>
      <AlertPaywall />
    </AlertModal>
    <LazyHydrate
      v-if="skipRender"
      never>
      <div>
        <div class="global-alert-selector">
          <AlertsHomeBodyBlock/>
        </div>
      </div>
    </LazyHydrate>
    <AlertsHomeBodyBlock v-else/>
  </div>
</template>

<script>

import LazyHydrate from 'vue-lazy-hydration';
import AlertsHomeBodyBlock from './page-segments/AlertsHomeBodyBlock.vue';
import {
  Page,
  AlertModal,
  AlertPaywall,
} from 'global-components';
import { isIPLogin } from 'global-utils';

export default {
  name: 'alerts-home-page',
  components: {
    LazyHydrate,
    AlertsHomeBodyBlock,
    AlertModal,
    AlertPaywall,
  },
  extends: Page,
  data() {
    return {
      showIPUserModal: false,
      skipRender: true,
    };
  },
  mounted() {
    this.showIPUserModal = isIPLogin(this);

    // window.subPaywallShow gets injected by Zephr if we want to show paywall
    // The code can be found in the component library of Zephr
    this.skipRender = !!window.subPaywallShow;
    console.log(`#GLOBAL-ALERT:: skipRender= "${this.skipRender}", zephrObject= "${JSON.stringify(window.Zephr)}"`);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$store.state.alertsEnabled || vm.$pubCtx.alert.disabled) {
        // throw new NotFoundError('Page not found.');
        next('/NotFound');
      }
    });
  }
};
</script>
