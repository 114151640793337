<template>
  <div class="mb-4 mt-4">
    <AlertInfoBanner
      v-if="showInfoBanner"
      v-on:alertInfoBannerClosed="infoBannerClosed"
      :alert-info-banner-closed-cookie="alertInfoBannerClosedCookie"
      :alert-info-banner-closed-time-cookie="alertInfoBannerClosedTimeCookie"/>
    <AlertPromoPopularTopics/>
    <div class="panel mb-4 mt-4">
      <row>
        <column
          xs="12"
          lg="9"
          class="ff-flama">
          <NewsPanel/>
        </column>
        <column
          xs="12"
          lg="3">
          <SettingsPanel class="d-none d-lg-block"/>
        </column>
      </row>
    </div>
  </div>
</template>

<script>
import {
  AlertPromoPopularTopics,
  NewsPanel,
  SettingsPanel,
  AlertInfoBanner,
} from 'global-components';

export default {
  name: 'alerts-home-page',
  components: {
    AlertPromoPopularTopics,
    NewsPanel,
    SettingsPanel,
    AlertInfoBanner,
  },
  data() {
    return {
      showInfoBanner: true,
      alertInfoBannerClosedCookie: 'alertInfoBannerClosed',
      alertInfoBannerClosedTimeCookie: 'alertInfoBannerClosedAt',
      resetAlertCookieBeforeTIme: 1585119345863,
    };
  },
  async mounted() {
    await this.checkIfShowInfoBanner();
  },
  methods: {
    infoBannerClosed() {
      this.showInfoBanner = false;
    },
    async checkIfShowInfoBanner() {
      const hasAcceptedCookie = await localStorage.getItem(this.alertInfoBannerClosedCookie) === 'true';
      const cookieAcceptanceTime = await localStorage.getItem(this.alertInfoBannerClosedTimeCookie) || false;
      const hasAcceptedAfterResetTime =
        cookieAcceptanceTime
          ? Number.parseInt(cookieAcceptanceTime) > this.resetAlertCookieBeforeTIme
          : false;
      this.showInfoBanner = !(hasAcceptedCookie && hasAcceptedAfterResetTime);
    }
  }
};
</script>
